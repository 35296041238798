import React, { FunctionComponent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Icon } from 'components';
import WithBackground from 'hocs';
import { saveAs } from 'file-saver';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import getHost from '../../utils/host';

const Download: FunctionComponent = () => {
  const { t } = useTranslation('main');
  const history = useHistory();
  const link = getHost();

  useEffect(() => {
    saveAs(link.host, link.name);
  }, [link]);

  return (
    <>
      <span className={styles.titleWithMargin}>{t('downloadPage.titleOne')}</span>
      <span className={styles.title}>{t('downloadPage.titleTwo')}</span>
      <Button
        className={styles.backButton}
        classes={{ startIcon: styles.backIcon }}
        onClick={() => {
          history.push('/');
        }}
        startIcon={<Icon icon="next" size={20} />}
        variant="contained"
      >
        <span className={styles.backText}>{t('downloadPage.back')}</span>
      </Button>
      <div className={styles.downloading}>
        <div className={styles.arrow}>
          <div className={styles.line} />
          <Icon className={styles.point} color="#51fff5" icon="next" size={20} />
        </div>
        <span className={styles.file}>{t('downloadPage.file')}</span>
      </div>
    </>
  );
};

export default WithBackground(Download);
