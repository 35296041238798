import ParticlesAnimationTwo from 'components/ParticlesAnimation/ParticlesAnimationTwo';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { technologies } from '../../../../constants';
import styles from './index.module.scss';

const TechnologiesSection: FunctionComponent = () => {
  const { t } = useTranslation('main');

  return (
    <div className={styles.technologiesSection}>
      <ParticlesAnimationTwo particlesNumberValue={50} />
      <span className={styles.title}>{t('technologiesSection.title')}</span>
      <div className={styles.imageWrapper}>
        {technologies.map(({ alt, image }) => (
          <img src={image} key={alt} alt={alt} className={styles.image} />
        ))}
      </div>
    </div>
  );
};

export default TechnologiesSection;
