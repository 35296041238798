import getPlatform from './platform';

const getHost = () => {
  switch (getPlatform()) {
    case 'mac':
      return {
        host: 'https://go2future-storage.s3-sa-east-1.amazonaws.com/Miembros/axel/Go2Virtual.dmg',
        name: 'Go2Virtual.dmg',
      };
    case 'android': {
      return { host: '', name: '' };
    }
    case 'iOS': {
      return { host: '', name: '' };
    }
    default: {
      return {
        host: 'https://go2future-storage.s3-sa-east-1.amazonaws.com/Miembros/axel/Go2Virtual_FullInstaller.exe',
        name: 'Go2Virtual.exe',
      };
    }
  }
};

export default getHost;
