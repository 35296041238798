import { isAndroid, isIOS, isMacOs } from 'react-device-detect';

const getPlatform = (): string => {
  let platform = 'windows';

  if (isMacOs) {
    platform = 'mac';
  } else if (isAndroid) {
    platform = 'android';
  } else if (isIOS) {
    platform = 'iOS';
  }

  return platform;
};

export default getPlatform;
