import { Footer, Header } from 'components';
import React, { FunctionComponent } from 'react';

import image1 from '../../assets/explanations/1.jpg';
import image2 from '../../assets/explanations/2.jpg';

const GuidePage: FunctionComponent = () => {
  return (
    <div style={{ backgroundColor: '#111111' }}>
      <Header />
      <img style={{ width: '100%', marginTop: 100 }} src={image1} alt="" />
      <img style={{ width: '100%' }} src={image2} alt="" />
      <Footer />
    </div>
  );
};

export default GuidePage;
