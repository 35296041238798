import React, { FunctionComponent, useState } from 'react';
import { InputAdornment, IconButton, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import classNames from 'classnames';

import styles from './index.module.scss';

interface FormInputProps {
  className?: string;
  error?: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  helperText?: string | false | {} | (string | {})[] | null | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef?: (ref: any) => void;
  fullWidth?: boolean;
  multiline?: boolean;
  rows?: number;
  rowsMax?: number;
  label: string;
  name?: string;
  select?: boolean;
  textArea?: boolean;
  type?: string;
}

const FormInput: FunctionComponent<FormInputProps> = ({
  error = false,
  children,
  className,
  helperText,
  inputRef,
  label,
  name,
  fullWidth = false,
  multiline = false,
  rows,
  rowsMax,
  select = false,
  textArea = false,
  type,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const shouldShowPassword = showPassword ? 'text' : 'password';
  const inputClass = classNames(textArea ? styles.textArea : styles.field, className);

  return (
    <TextField
      className={inputClass}
      FormHelperTextProps={{
        className: styles.helperText,
      }}
      InputLabelProps={{
        className: styles.focusedInput,
        classes: {
          shrink: styles.shrinkInput,
        },
      }}
      InputProps={{
        classes: {
          focused: styles.focused,
          input: styles.input,
          underline: styles.underline,
        },
        endAdornment: type === 'password' && (
          <InputAdornment position="end">
            <IconButton onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      error={error}
      helperText={helperText}
      inputRef={inputRef}
      label={label}
      name={name}
      select={select}
      fullWidth={fullWidth}
      multiline={multiline}
      rows={rows}
      rowsMax={rowsMax}
      type={type === 'password' ? shouldShowPassword : undefined}
      variant="filled"
    >
      {children}
    </TextField>
  );
};

export default FormInput;
