import aws from 'assets/aws.png';
import cart from 'assets/cart.png';
import howItWorksBack from 'assets/screenshots/howitworksback.png';
import howItWorksFront from 'assets/screenshots/howitworksfront.jpg';
import integration from 'assets/integration.png';
import investor from 'assets/screenshots/investors.png';
import khronos from 'assets/logo_khronos.png';
import mallscreenshot1 from 'assets/screenshots/mall/1.jpg';
import mallscreenshot10 from 'assets/screenshots/mall/10.jpg';
import mallscreenshot2 from 'assets/screenshots/mall/2.jpg';
import mallscreenshot3 from 'assets/screenshots/mall/3.jpg';
import mallscreenshot4 from 'assets/screenshots/mall/4.jpg';
import mallscreenshot5 from 'assets/screenshots/mall/5.jpg';
import mallscreenshot6 from 'assets/screenshots/mall/6.jpg';
import mallscreenshot7 from 'assets/screenshots/mall/7.jpg';
import mallscreenshot8 from 'assets/screenshots/mall/8.jpg';
import mallscreenshot9 from 'assets/screenshots/mall/9.jpg';
import oculus from 'assets/oculus.png';
import productDoohan1 from 'assets/screenshots/product/Doohan/1.jpg';
import productDoohan2 from 'assets/screenshots/product/Doohan/2.jpg';
import productDoohan3 from 'assets/screenshots/product/Doohan/3.jpg';
import productDoohan4 from 'assets/screenshots/product/Doohan/4.jpg';
import productDoohan5 from 'assets/screenshots/product/Doohan/5.jpg';
import productDoohan6 from 'assets/screenshots/product/Doohan/6.jpg';
import productDoohan7 from 'assets/screenshots/product/Doohan/7.jpg';
import productDoohan8 from 'assets/screenshots/product/Doohan/8.jpg';
import productDoohan9 from 'assets/screenshots/product/Doohan/9.jpg';
import productG2M1 from 'assets/screenshots/product/Go2Market/1.jpg';
import productG2M2 from 'assets/screenshots/product/Go2Market/2.jpg';
import productG2M3 from 'assets/screenshots/product/Go2Market/3.jpg';
import productG2M4 from 'assets/screenshots/product/Go2Market/4.jpg';
import productG2M5 from 'assets/screenshots/product/Go2Market/5.jpg';
import productG2M6 from 'assets/screenshots/product/Go2Market/6.jpg';
import productTech1 from 'assets/screenshots/product/TechAndFly/1.jpg';
import productTech2 from 'assets/screenshots/product/TechAndFly/2.jpg';
import productTech3 from 'assets/screenshots/product/TechAndFly/3.jpg';
import productTech6 from 'assets/screenshots/product/TechAndFly/6.jpg';
import productTech7 from 'assets/screenshots/product/TechAndFly/7.jpg';
import productTech8 from 'assets/screenshots/product/TechAndFly/8.jpg';
import render from 'assets/render.png';
import seller from 'assets/screenshots/sellers.jpg';
import shopper from 'assets/screenshots/shoppers.png';
import step1 from 'assets/recuadro_step_1_cyan.svg';
import step2 from 'assets/recuadro_step_2_cyan.svg';
import step3 from 'assets/recuadro_step_3_cyan.svg';
import step4 from 'assets/recuadro_step_4_cyan.svg';
import storeDoohanScrn1 from 'assets/screenshots/store/Doohan/1.jpg';
import storeDoohanScrn2 from 'assets/screenshots/store/Doohan/2.jpg';
import storeDoohanScrn3 from 'assets/screenshots/store/Doohan/3.jpg';
import storeDoohanScrn4 from 'assets/screenshots/store/Doohan/4.jpg';
import storeDoohanScrn5 from 'assets/screenshots/store/Doohan/5.jpg';
import storeDoohanScrn6 from 'assets/screenshots/store/Doohan/6.jpg';
import storeDoohanScrn7 from 'assets/screenshots/store/Doohan/7.jpg';
import storeDoohanScrn8 from 'assets/screenshots/store/Doohan/8.jpg';
import storeGo2Scrn1 from 'assets/screenshots/store/Go2market/1.jpg';
import storeGo2Scrn2 from 'assets/screenshots/store/Go2market/2.jpg';
import storeGo2Scrn3 from 'assets/screenshots/store/Go2market/3.jpg';
import storeGo2Scrn4 from 'assets/screenshots/store/Go2market/4.jpg';
import storeGo2Scrn5 from 'assets/screenshots/store/Go2market/5.jpg';
import storeGo2Scrn6 from 'assets/screenshots/store/Go2market/6.jpg';
import storeGo2Scrn7 from 'assets/screenshots/store/Go2market/7.jpg';
import storeTEchScrn1 from 'assets/screenshots/store/TechAndFly/1.jpg';
import storeTEchScrn2 from 'assets/screenshots/store/TechAndFly/2.jpg';
import storeTEchScrn3 from 'assets/screenshots/store/TechAndFly/3.jpg';
import storeTEchScrn4 from 'assets/screenshots/store/TechAndFly/4.jpg';
import storeTEchScrn5 from 'assets/screenshots/store/TechAndFly/5.jpg';
import unrealEngine from 'assets/unreal-engine.png';
import vive from 'assets/vive.png';
import vr from 'assets/stock/womangrabbing.jpg';

const availableIcons = ['android', 'iOS', 'windows', 'vr'];

const experiences = [
  {
    image: render,
    text: 'experiences.render.text',
    title: 'experiences.render.title',
  },
  {
    image: cart,
    text: 'experiences.cart.text',
    title: 'experiences.cart.title',
  },
  {
    image: integration,
    text: 'experiences.integration.text',
    title: 'experiences.integration.title',
  },
];

const footerLanguageMenu = [
  {
    icon: 'uk',
    language: 'footerLanguageMenu.language.en',
    //language: 'English',
    name: 'footerLanguageMenu.name.english',
    lng: 'en',
  },
  {
    icon: 'spain',
    language: 'footerLanguageMenu.language.es',
    //language: 'Español',
    name: 'footerLanguageMenu.name.spanish',
    lng: 'es',
  },
  // {
  //   icon: 'japan',
  //   language: 'footerLanguageMenu.language.japanese',
  //   name: 'footerLanguageMenu.name.japanese',
  // },
  // {
  //   icon: 'china',
  //   language: 'footerLanguageMenu.language.chinese',
  //   name: 'footerLanguageMenu.name.chinese',
  // },
];

const commonRetailersMenu = [
  {
    text: 'retailersMenu.metrics',
    redirect: '/retailers#metrics',
  },
  {
    text: 'retailersMenu.comparison',
    redirect: '/retailers#comparison',
  },
  {
    text: 'retailersMenu.contact',
    redirect: '/retailers#contact',
  },
];

const commonRetailersMenuAux = [
  {
    text: 'retailersMenu.yourStore',
    redirect: '/retailers#maincontent',
  },
  {
    text: 'retailersMenu.metrics',
    redirect: '/retailers#metrics',
  },
  {
    text: 'retailersMenu.comparison',
    redirect: '/retailers#comparison',
  },
  {
    text: 'retailersMenu.benefits',
    redirect: '/retailers#benefits',
  },
  {
    text: 'retailersMenu.contact',
    redirect: '/retailers#contact',
  },
];

const commonShoppersMenu = [
  {
    text: 'shoppersMenu.steps',
    redirect: '/shoppers#steps',
  },
  {
    text: 'shoppersMenu.whyGo2',
    redirect: '/shoppers#whygo2',
  },
  {
    text: 'shoppersMenu.nav',
    redirect: '/shoppers#navguide',
  },
];

const commonShoppersMenuAux = [
  {
    text: 'shoppersMenu.steps',
    redirect: '/shoppers#steps',
  },
  {
    text: 'shoppersMenu.whyGo2',
    redirect: '/shoppers#whygo2',
  },
  {
    text: 'shoppersMenu.signIn',
    redirect: 'https://shop.metaverse-mall.io/',
  },
];

const footerLinks = [
  { anchor: 'howItWorks', text: 'footer.forRetailers' },
  { anchor: 'howItWorks', text: 'footer.forShoppers' },
  { anchor: 'ourValues', text: 'footer.ourValues' },
  { anchor: 'contactUs', text: 'footer.contactUs' },
];

const headerLinks = [
  { anchor: '/retailers', text: 'headerLinks.forSellers' },
  { anchor: '/shoppers', text: 'headerLinks.forShoppers' },
  { anchor: 'contactUs', text: 'headerLinks.contactUs' },
  { anchor: 'https://shop.metaverse-mall.io/', text: 'headerLinks.signIn' },
];

const interestOptions = [
  {
    value: 'Seller',
    label: 'interestOptions.seller',
  },
  {
    value: 'Investor',
    label: 'interestOptions.investor',
  },
  {
    value: 'Shopper',
    label: 'interestOptions.shopper',
  },
];

const languageMenu = [
  {
    icon: 'uk',
    language: 'languageMenu.language.english',
    name: 'languageMenu.name.english',
  },
  {
    icon: 'spain',
    language: 'languageMenu.language.spanish',
    name: 'languageMenu.name.spanish',
  },
  {
    icon: 'japan',
    language: 'languageMenu.language.japanese',
    name: 'languageMenu.name.japanese',
  },
  {
    icon: 'china',
    language: 'languageMenu.language.chinese',
    name: 'languageMenu.name.chinese',
  },
];

const products = [
  [
    mallscreenshot1,
    mallscreenshot2,
    mallscreenshot3,
    mallscreenshot4,
    mallscreenshot5,
    mallscreenshot6,
    mallscreenshot7,
    mallscreenshot8,
    mallscreenshot9,
    mallscreenshot10,
  ],
  [
    storeGo2Scrn5,
    storeTEchScrn2,
    storeTEchScrn1,
    storeGo2Scrn1,
    storeGo2Scrn7,
    storeDoohanScrn5,
    storeTEchScrn5,
    storeDoohanScrn8,
    storeGo2Scrn4,
    storeGo2Scrn3,
    storeTEchScrn4,
    storeDoohanScrn1,
    storeDoohanScrn4,
    storeDoohanScrn7,
    storeDoohanScrn2,
    storeDoohanScrn3,
    storeGo2Scrn2,
    storeTEchScrn3,
    storeDoohanScrn6,
    storeGo2Scrn6,
    storeGo2Scrn5,
  ],
  [
    productTech1,
    productTech8,
    productG2M5,
    productTech2,
    productG2M3,
    productDoohan1,
    productTech3,
    productG2M6,
    productDoohan9,
    productG2M1,
    productDoohan8,
    productTech6,
    productG2M4,
    productG2M2,
    productDoohan2,
    productDoohan6,
    productDoohan4,
    productDoohan5,
    productDoohan7,
    productDoohan3,
    productTech7,
  ],
];

const howItWorksProducts = [
  ...[howItWorksFront, howItWorksBack],
  ...[vr, howItWorksFront],
  ...[howItWorksBack, vr],
  ...[howItWorksFront, howItWorksBack],
];

const productTabs = ['productTabs.intoTheMall', 'productTabs.intoTheStore', 'productTabs.products'];

const technologies = [
  {
    alt: 'AWS',
    image: aws,
  },
  {
    alt: 'Unreal Engine',
    image: unrealEngine,
  },
  {
    alt: 'VIVE',
    image: vive,
  },
  {
    alt: 'Oculus',
    image: oculus,
  },
];
const steps = [
  {
    image: step1,
  },
  {
    image: step2,
  },
  {
    image: step3,
  },
  {
    image: step4,
  },
];
const partners = [
  {
    alt: 'Khronos Group',
    image: khronos,
  },
];

const types = [
  {
    image: seller,
    text: 'types.sellers.text',
    title: 'types.sellers.title',
  },
  {
    image: shopper,
    text: 'types.shoppers.text',
    title: 'types.shoppers.title',
  },
  {
    image: investor,
    text: 'types.investors.text',
    title: 'types.investors.title',
  },
];
const retailers = [
  {
    text: 'retailersPage.retailers.one.text',
    title: 'retailersPage.retailers.one.title',
  },
  {
    text: 'retailersPage.retailers.two.text',
    title: 'retailersPage.retailers.two.title',
  },
  {
    text: 'retailersPage.retailers.three.text',
    title: 'retailersPage.retailers.three.title',
  },
];

const retailersList = [
  'retailersPage.retailersList.one',
  'retailersPage.retailersList.two',
  'retailersPage.retailersList.three',
  'retailersPage.retailersList.four',
];

export {
  availableIcons,
  experiences,
  interestOptions,
  headerLinks,
  howItWorksProducts,
  footerLanguageMenu,
  commonRetailersMenu,
  commonRetailersMenuAux,
  commonShoppersMenu,
  commonShoppersMenuAux,
  footerLinks,
  languageMenu,
  products,
  productTabs,
  retailers,
  retailersList,
  technologies,
  partners,
  steps,
  types,
};
