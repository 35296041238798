import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles, Grid } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
//import { Check } from '@material-ui/icons';
import { Footer } from 'components';
import { OurMetricsSection } from 'pages/Landing/Sections';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import FormSection from '../Landing/Sections/FormSection';
import styles from './index.module.scss';
// Icons for table
import Check from '../../assets/retailers/table/check.svg';
import Icon3D from '../../assets/retailers/table/3d.svg';
import IconImmersive from '../../assets/retailers/table/immersive.svg';
import IconMobile from '../../assets/retailers/table/mobile-desktop.svg';
import IconVirtualShopper from '../../assets/retailers/table/virtual-shopper.svg';
import IconBuyPay from '../../assets/retailers/table/buy-pay.svg';
import IconSwitch from '../../assets/retailers/table/switch.svg';
import IconShop from '../../assets/retailers/table/shop.svg';

const rows = [
  {
    name: 'retailersPage.rows.one',
    go2Future: true,
    stores: false,
    traditional: false,
    transparent: false,
    icon: Icon3D,
  },
  {
    name: 'retailersPage.rows.two',
    go2Future: true,
    stores: false,
    traditional: false,
    transparent: true,
    icon: IconImmersive,
  },
  {
    name: 'retailersPage.rows.three',
    go2Future: true,
    stores: false,
    traditional: false,
    transparent: false,
    icon: IconMobile,
  },
  {
    name: 'retailersPage.rows.four',
    go2Future: true,
    stores: false,
    traditional: false,
    transparent: true,
    icon: IconVirtualShopper,
  },
  {
    name: 'retailersPage.rows.six',
    go2Future: true,
    stores: true,
    traditional: true,
    transparent: false,
    icon: IconBuyPay,
  },
  {
    name: 'retailersPage.rows.seven',
    go2Future: true,
    stores: false,
    traditional: false,
    transparent: true,
    icon: IconSwitch,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
    },
  },
}));

const Retailers: FunctionComponent = () => {
  const { t: mainTranslation } = useTranslation('main');
  const classes = useStyles();

  React.useEffect(() => {
    if (window.location.hash) {
      window.location.href = window.location.hash;
    }
  }, []);

  return (
    <div>
      <div className={styles.retailers} id="maincontent">
        <div
          className={styles.content}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span className={styles.title}>{mainTranslation('retailersPage.top_title')}</span>
          <br />
          <span className={styles.title}>{mainTranslation('retailersPage.title')}</span>
          <span className={styles.logo} />
          <span className={styles.subtitle}>{mainTranslation('retailersPage.subtitle')}</span>
        </div>
      </div>

      <OurMetricsSection />

      <div className={classes.root} id="comparison">
        <div className={styles.table}>
          <div className={styles.ourFeatures}>
            {isMobile
              ? mainTranslation('retailersPage.tableTitle.mobile')
              : mainTranslation('retailersPage.tableTitle.desktop')}
          </div>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {isMobile ? (
                    <div />
                  ) : (
                    <>
                      <TableCell className={styles.cellTransparent} />
                      <TableCell align="center" className={styles.cellTransparent}>
                        Metamall
                      </TableCell>
                      <TableCell align="center" className={styles.cellTransparentBold}>
                        {mainTranslation('retailersPage.cells.two')}
                      </TableCell>
                      <TableCell align="center" className={styles.cellTransparentLast}>
                        {mainTranslation('retailersPage.cells.three')}
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {isMobile
                  ? rows.map((row, idx) => (
                      <TableRow key={row.name}>
                        <TableCell className={styles.cellColor} component="th" scope="row">
                          {mainTranslation(row.name)}
                        </TableCell>
                      </TableRow>
                    ))
                  : rows.map((row, idx) => (
                      <TableRow key={row.name}>
                        <TableCell
                          className={row.transparent ? styles.cellTransparentBold : styles.cellGreyColor}
                          component="th"
                          scope="row"
                        >
                          <div className={styles.iconCellContainer}>
                            <img src={row.icon} className={styles.iconTable} alt="" />
                            {mainTranslation(row.name)}
                          </div>
                        </TableCell>
                        <TableCell
                          align="center"
                          className={row.transparent ? styles.cellTransparentBold : styles.cellGreyColor}
                        >
                          {row.go2Future && <img src={Check} className={styles.iconCheck} alt="" />}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={row.transparent ? styles.cellTransparentBold : styles.cellGreyColor}
                        >
                          {row.stores && <img src={Check} className={styles.iconCheck} alt="" />}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={row.transparent ? styles.cellTransparentBoldLast : styles.cellGreyColorLast}
                        >
                          {row.traditional && <img src={Check} className={styles.iconCheck} alt="" />}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <FormSection />
      <Footer />
    </div>
  );
};
export default Retailers;
