import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import styles from './index.module.scss';
import stepsSvg from '../../../../assets/steps_desktop.svg';
import stepsSvgMobile from '../../../../assets/steps_mobile.svg';
import { ReactComponent as StepsSvgES } from '../../../../assets/steps_desktop_SPA.svg';

const StepByStepSection: FunctionComponent = () => {
  const { t, i18n } = useTranslation('main');

  return (
    <div className={styles.stepByStepSection} id="steps">
      <span className={styles.title}>{t('shoppers.stepsTitle')}</span>
      <div className={styles.wrapper}>
        {!isMobile && /es/.test(i18n.language) ? (
          <StepsSvgES className={styles.stepsGroup} style={{ height: '100%', marginTop: '3%' }} />
        ) : (
          <img src={isMobile ? stepsSvgMobile : stepsSvg} className={styles.stepsGroup} alt="step by step group" />
        )}
      </div>
    </div>
  );
};

export default StepByStepSection;
