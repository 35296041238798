import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import WithBackground from 'hocs';
import { Icon, FormInput } from 'components';
import styles from './index.module.scss';

type FormData = {
  email: string;
  password: string;
  repassword: string;
};

const Register: FunctionComponent = () => {
  const { errors, handleSubmit, register } = useForm<FormData>();
  const { t: mainTranslation } = useTranslation('main');
  const { t: commonTranslation } = useTranslation('common');
  const onSubmit = ({ email, password, repassword }: FormData) => console.log(email, password, repassword);

  return (
    <>
      <div className={styles.accountsWrapper}>
        <span className={styles.useOtherAccount}>{mainTranslation('registerPage.useOtherAccount')}</span>
        <Button>
          <Icon className={styles.icon} color="#ffffff" icon="google" size={26} />
          <span className={styles.accountText}>{mainTranslation('registerPage.google')}</span>
        </Button>
        <Button>
          <Icon className={styles.icon} color="#ffffff" icon="facebook" size={36} />
          <span className={styles.accountText}>{mainTranslation('registerPage.facebook')}</span>
        </Button>
      </div>
      <form className={styles.form} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formWrapper}>
          <FormInput
            error={!!errors.email}
            helperText={!!errors.email && commonTranslation('wrongEmail')}
            inputRef={register({
              pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              required: true,
            })}
            label={mainTranslation('registerPage.form.email')}
            name="email"
          />
          <FormInput
            error={!!errors.password}
            helperText={!!errors.password && commonTranslation('fieldRequired')}
            inputRef={register({ required: true })}
            label={mainTranslation('registerPage.form.password')}
            name="password"
            type="password"
          />
          <FormInput
            error={!!errors.repassword}
            helperText={!!errors.repassword && commonTranslation('fieldRequired')}
            inputRef={register({ required: true })}
            label={mainTranslation('registerPage.form.rePassword')}
            name="repassword"
            type="password"
          />
          <div className={styles.formActions}>
            <div className={styles.termsAndConditions}>
              <span className={styles.termsText}>{mainTranslation('registerPage.form.accepting')}</span>
              <span className={styles.terms}>{mainTranslation('registerPage.form.terms')}</span>
            </div>
            <Button
              type="submit"
              className={styles.submitButton}
              endIcon={<Icon color="#000000" icon="next" size={20} />}
            >
              <span className={styles.submit}>{mainTranslation('registerPage.form.createAccount')}</span>
            </Button>
          </div>
        </div>
      </form>
      <div className={styles.signUpWrapper}>
        <span className={styles.needAccount}>{mainTranslation('registerPage.form.alreadyAccount')}</span>
        &nbsp;
        <Link className={styles.signUp} to="/login">
          {mainTranslation('registerPage.form.signIn')}
        </Link>
      </div>
    </>
  );
};

export default WithBackground(Register);
