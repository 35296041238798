import React, { FunctionComponent } from 'react';
import { Footer } from 'components';
import { Grid } from '@material-ui/core';
import styles from './index.module.scss';
import Panorama from '../../assets/panorama.svg';
import Movil from '../../assets/movil.svg';
import Bolsa from '../../assets/bolsa.svg';
import Conversacion from '../../assets/conversacion.svg';
import Producto from '../../assets/producto.svg';
import Tienda from '../../assets/tienda-online.svg';
import { useTranslation } from 'react-i18next';
import { StepByStepSection } from 'pages/Landing/Sections';
import mouse from '../../assets/shoppers/mouse_config.svg';
import product from '../../assets/shoppers/product_view.svg';
import { ReactComponent as MouseEs } from '../../assets/shoppers/mouse_config_SPA.svg';
import { ReactComponent as ProductES } from '../../assets/shoppers/product_view_SPA.svg';
import mouseMobile from '../../assets/shoppers/mouse_config_mobile.svg';
import productMobile from '../../assets/shoppers/product_view_mobile.svg';
import { isMobile } from 'react-device-detect';
import ParticlesAnimation from 'components/ParticlesAnimation/ParticlesAnimation';

const Shoppers: FunctionComponent = (props) => {
  const { t: mainTranslation, i18n } = useTranslation('main');

  React.useEffect(() => {
    if (window.location.hash) {
      window.location.href = window.location.hash;
    }
  }, []);

  return (
    <div className={styles.generalContainer}>
      <div className={styles.mainSection}>
        <div
          className={styles.content}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            flexWrap: 'wrap',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', maxWidth: isMobile ? '100%' : '90vw' }}>
            <div className={styles.centered}>{mainTranslation('shoppers.imageTitle')}</div>
          </div>
        </div>
      </div>
      <div style={{ position: 'relative' }}>
        <ParticlesAnimation
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 0,
            width: '100%',
            height: '105%',
            //objectFit: 'contain',
          }}
          particlesNumberValue={300}
          onHoverMode="repulse"
        />
        <StepByStepSection />
        <div id="whygo2"></div>

        <div className={styles.stepsSubtitle}>
          <span className={styles.stepsSubtitle}>{mainTranslation('shoppers.stepsSubtitle1')}</span>
          <span className={styles.cian}>{mainTranslation('shoppers.stepsSubtitle2')}</span>
          <span className={styles.stepsSubtitle}>{mainTranslation('shoppers.stepsSubtitle3')}</span>
        </div>
        <Grid container direction="row" justify="center">
          <Grid item lg={2} xs={12}>
            <Grid container direction="column" alignItems="center" className={styles.stepContainer}>
              <img src={Panorama} alt="panoramic" className={styles.advantage} />
              <div className={styles.advantageText}>{mainTranslation('shoppers.shop1')}</div>
              <div className={styles.advantageText}>{mainTranslation('shoppers.shop2')}</div>
            </Grid>
          </Grid>
          <Grid item lg={2} xs={12}>
            <Grid container direction="column" alignItems="center" className={styles.stepContainer}>
              <img src={Movil} alt="panoramic" className={styles.advantage} />
              <div className={styles.advantageText}>{mainTranslation('shoppers.virtual1')}</div>
              <div className={styles.advantageText}>{mainTranslation('shoppers.virtual2')}</div>
            </Grid>
          </Grid>
          <Grid item lg={2} xs={12}>
            <Grid container direction="column" alignItems="center" className={styles.stepContainer}>
              <img src={Bolsa} alt="panoramic" className={styles.advantage} />
              <div className={styles.advantageText}>{mainTranslation('shoppers.contactless1')}</div>
              <div className={styles.advantageText}>{mainTranslation('shoppers.contactless2')}</div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" className={styles.containerBottom}>
          <Grid item lg={2} xs={12}>
            <Grid container direction="column" alignItems="center" className={styles.stepContainer}>
              <img src={Producto} alt="panoramic" className={styles.advantage} />
              <div className={styles.advantageText}>{mainTranslation('shoppers.prod1')}</div>
              <div className={styles.advantageText}>{mainTranslation('shoppers.prod2')}</div>
              <div className={styles.advantageText}>{mainTranslation('shoppers.prod3')}</div>
              <div className={styles.advantageText}>{mainTranslation('shoppers.prod4')}</div>
            </Grid>
          </Grid>
          <Grid item lg={2} xs={12}>
            <Grid container direction="column" alignItems="center" className={styles.stepContainer}>
              <img src={Conversacion} alt="panoramic" className={styles.advantage} />
              <div className={styles.advantageText}>{mainTranslation('shoppers.chat1')}</div>
              <div className={styles.advantageText}>{mainTranslation('shoppers.chat2')}</div>
            </Grid>
          </Grid>
          <Grid item lg={2} xs={12}>
            <Grid container direction="column" alignItems="center">
              <img src={Tienda} alt="panoramic" className={styles.advantage} />
              <div className={styles.advantageText}>{mainTranslation('shoppers.online')}</div>
            </Grid>
          </Grid>
        </Grid>

        <br />
        <br />
      </div>
      <Footer />
    </div>
  );
};
export default Shoppers;
