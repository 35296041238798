import { Button, Link as MaterialLink, Theme, useMediaQuery } from '@material-ui/core';
import React, { FunctionComponent, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/02 MetaverseMallwhite Logo.png';
import { useTranslation } from 'react-i18next';
import { footerLanguageMenu, commonRetailersMenu, footerLinks, commonShoppersMenu } from '../../constants';
import Icon from '../Icon';
import LanguageMenu from '../LanguageMenu';
import CollapsableMenu from '../CollapsableMenu';
import styles from './index.module.scss';
import ParticlesAnimation from 'components/ParticlesAnimation/ParticlesAnimation';

const Footer: FunctionComponent = () => {
  const { t: commonTranslation, i18n } = useTranslation('common');
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [isLanguageMenuVisible, setIsLanguageMenuVisible] = useState(false);
  const [isRetailersMenuVisible, setIsRetailersMenuVisible] = useState(false);
  const [isShoppersMenuVisible, setIsShoppersMenuVisible] = useState(false);

  // const [selectedLanguage, setSelectedLanguage] = useState(
  //   commonTranslation(`footerLanguageMenu.language.${i18n.language}`),
  // );
  const anchorRef = useRef<HTMLButtonElement>(null);
  const anchorRetailersRef = useRef<HTMLButtonElement>(null);
  const anchorShoppersRef = useRef<HTMLButtonElement>(null);
  const toggleLanguageMenu = () => setIsLanguageMenuVisible(!isLanguageMenuVisible);
  const toggleRetailersMenu = () => setIsRetailersMenuVisible(!isRetailersMenuVisible);
  const toggleShoppersMenu = () => setIsShoppersMenuVisible(!isShoppersMenuVisible);

  //const auxLenguage = /es/.test(i18n.language) ? 'es' : 'en';

  return (
    <div className={styles.footer}>
      <ParticlesAnimation
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 0,
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        }}
        particlesNumberValue={400}
        onHoverMode="repulse"
      />
      <div className={styles.logoWrapper}>
        <div className={styles.logo}>
          <a href="/">
            <img src={logo} className={styles.imageLogo} alt="logo" />
          </a>
        </div>
        <span className={styles.brand} style={{ zIndex: 2 }}>
          {commonTranslation('footer.goVirtual2020')}
          <span
            className={styles.lightAqua}
            style={{ cursor: 'pointer' }}
            onClick={function () {
              window.open('https://go2future.com/', '_blank');
            }}
          >
            {commonTranslation('footer.future')}
          </span>
          {commonTranslation('footer.goVirtualRights')}
        </span>
        <span className={styles.brand}>
          <span className={styles.lightAqua}>{commonTranslation('footer.brand2')}</span>
        </span>
      </div>
      <div className={styles.column}>
        <Button
          className={styles.text}
          onClick={toggleRetailersMenu}
          key={footerLinks[0].text}
          ref={anchorRetailersRef}
        >
          {commonTranslation(footerLinks[0].text)}
          <Icon className={styles.arrowDown} color="#FFFFFF" icon="down" />
        </Button>
        <Button className={styles.text} onClick={toggleShoppersMenu} key={footerLinks[1].text} ref={anchorShoppersRef}>
          {commonTranslation(footerLinks[1].text)}
          <Icon className={styles.arrowDown} color="#FFFFFF" icon="down" />
        </Button>
        <Link className={styles.text} to="/values" key="values">
          {commonTranslation(footerLinks[2].text)}
        </Link>
        <a className={styles.text} href="mailto:invest@go2future.com" key={footerLinks[3].text}>
          {commonTranslation(footerLinks[3].text)}
        </a>
      </div>
      {/* <div className={styles.column}>
        <Link to="/guide" className={styles.text}>
          {commonTranslation('footer.navigationGuide')}
        </Link>
        <Link to="/" className={styles.text}>
          {commonTranslation('footer.privacyPolicy')}
        </Link>
        <Link to="/" className={styles.text}>
          {commonTranslation('footer.termsAndConditions')}
        </Link>
        <Link to="/" className={styles.text}>
          {commonTranslation('footer.customerService')}
        </Link>
      </div> */}
      <div className={styles.column}>
        {/* <Link to="/" className={styles.text}>
          {commonTranslation('footer.productReturnPolicy')}
        </Link> */}
        {/* <Link to="/investors" className={styles.text}>
          {commonTranslation('footer.investors')}
        </Link> */}
        <Button className={styles.textAqua} onClick={toggleLanguageMenu} ref={anchorRef}>
          {commonTranslation(`footerLanguageMenu.language.${/es/.test(i18n.language) ? 'es' : 'en'}`)}
          <Icon className={styles.arrowDown} color="#51fff5" icon="down" />
        </Button>
      </div>
      <div className={styles.columnSocial}>
        <span className={styles.followUs}>{commonTranslation('footer.followUs')}</span>
        <span className={styles.iconWrapper}>
          {/* <MaterialLink href="https://www.instagram.com/go2virtual/" rel="noopener" target="_blank">
            <Icon className={styles.icon} icon="instagram" size={!isMobile ? 30 : 26} />
          </MaterialLink>
          <MaterialLink
            href="https://www.facebook.com/Go2Virtual-115974783641291/?view_public_for=115974783641291"
            rel="noopener"
            target="_blank"
          >
            <Icon className={styles.icon} icon="facebook" size={!isMobile ? 30 : 26} />
          </MaterialLink> */}
          <MaterialLink href="https://www.youtube.com/channel/UChYBvHHOqI2PIHTD9doyAFw" rel="noopener" target="_blank">
            <Icon className={styles.icon} icon="youtube" size={!isMobile ? 30 : 26} />
          </MaterialLink>
          <MaterialLink href="https://www.linkedin.com/company/go2future/" rel="noopener" target="_blank">
            <Icon className={styles.icon} color="#51fff5" icon="linkedin" size={!isMobile ? 26 : 19} />
          </MaterialLink>
          {/* <MaterialLink href="https://twitter.com/Go2Virtual" rel="noopener" target="_blank">
              <Icon className={styles.icon} color="#51fff5" icon="twitter" size={!isMobile ? 26 : 19} />
            </MaterialLink> */}
          <MaterialLink href="https://vimeo.com/465388601" rel="noopener" target="_blank">
            {isMobile ? (
              <div style={{ paddingTop: '1.6rem' }}>
                <Icon className={styles.icon} color="#51fff5" icon="vimeo" size={50} />
              </div>
            ) : (
              <Icon className={styles.icon} color="#51fff5" icon="vimeo" size={60} />
            )}
          </MaterialLink>
        </span>
      </div>

      <LanguageMenu
        anchorRef={anchorRef}
        isVisible={isLanguageMenuVisible}
        menuItems={footerLanguageMenu}
        onClose={toggleLanguageMenu}
        //onItemSelect={(language) => setSelectedLanguage(commonTranslation(language))}
        transformOrigin={{ horizontal: -100, vertical: 0 }}
      />

      <CollapsableMenu
        anchorRef={anchorRetailersRef}
        isVisible={isRetailersMenuVisible}
        menuItems={commonRetailersMenu}
        onClose={toggleRetailersMenu}
        transformOrigin={{ horizontal: 35, vertical: -50 }}
        onRedirect={(x) => window.location.replace(x)}
      />

      <CollapsableMenu
        anchorRef={anchorShoppersRef}
        isVisible={isShoppersMenuVisible}
        menuItems={commonShoppersMenu}
        onClose={toggleShoppersMenu}
        transformOrigin={{ horizontal: 35, vertical: -50 }}
        onRedirect={(x) => window.location.replace(x)}
      />
    </div>
  );
};

export default Footer;
