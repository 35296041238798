import React, { FunctionComponent } from 'react';
import { isMobile } from 'react-device-detect';

import styles from './index.module.scss';

const VideoSection: FunctionComponent = () => {
  return (
    <div className={styles.videoSection}>
      <iframe
        title="video"
        loading="lazy"
        src="https://www.youtube.com/embed/GtAQoeImnkw?autoplay=0&rel=0&iv_load_policy=3&color=white"
        width="100%"
        allowFullScreen
        height={isMobile ? 200 : 720}
        frameBorder="0"
      />
    </div>
  );
};
export default VideoSection;
