import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '../Icon';
import styles from './index.module.scss';
import { availableIcons } from '../../constants';

const AvailablePlatforms: FunctionComponent = () => {
  const { t } = useTranslation('common');

  return (
    <div className={styles.availableWrapper}>
      <span className={styles.available}>{t('availableSoonFor')}</span>
      {availableIcons.map((icon) => (
        <Icon icon={icon} color="#FFFFFF" key={icon} size={30} className={styles.icon} />
      ))}
    </div>
  );
};
export default AvailablePlatforms;
