import React, { FunctionComponent, RefObject } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import ParticlesAnimation from 'components/ParticlesAnimation/ParticlesAnimation';

interface MenuItem {
  text: string;
  redirect: string;
}

interface TransformOrigin {
  horizontal: 'center' | 'left' | 'right' | number;
  vertical: 'bottom' | 'center' | 'top' | number;
}

interface CollapsableMenuProps {
  anchorRef: RefObject<HTMLButtonElement>;
  disableScrollLock?: boolean;
  isVisible: boolean;
  menuItems: Array<MenuItem>;
  onClose: () => void;
  onRedirect: (redirect: string) => void;
  transformOrigin?: TransformOrigin;
}

const CollapsableMenu: FunctionComponent<CollapsableMenuProps> = ({
  anchorRef,
  disableScrollLock = false,
  isVisible,
  menuItems,
  onClose,
  onRedirect,
  transformOrigin = { horizontal: 'left', vertical: 'top' },
}) => {
  const { t } = useTranslation('common');

  return (
    <Menu
      anchorEl={anchorRef.current}
      classes={{ paper: styles.menu }}
      disableScrollLock={disableScrollLock}
      onClose={onClose}
      open={isVisible}
      transformOrigin={transformOrigin}
    >
      <ParticlesAnimation
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 0,
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        }}
        particlesNumberValue={400}
        onHoverMode="repulse"
      />
      {menuItems.map(({ text, redirect }) => (
        <MenuItem
          key={text}
          classes={{ root: styles.menuItem }}
          onClick={() => {
            onClose();
            onRedirect(t(redirect));
          }}
        >
          <span className={styles.language}>{t(text)}</span>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default CollapsableMenu;
