import { Download, Landing, Login, Register } from 'pages';
import React, { FunctionComponent } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';

import CustomerIn from 'pages/CustomerIn';
import GuidePage from 'pages/Tutorial';
import { Header } from 'components';
import Investors from 'pages/Investors';
import Retailers from 'pages/Retailers';
import Shoppers from 'pages/Shoppers';
import { StylesProvider } from '@material-ui/core/styles';
import ValuesPage from 'pages/Values';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Work Sans',
  },
});

const App: FunctionComponent = () => (
  <ThemeProvider theme={theme}>
    <StylesProvider injectFirst>
      <Router>
        <Switch>
          <Route path="/download">
            <Download />
          </Route>
          <Route exact path="/register">
            <Register />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/values" component={ValuesPage} />
          <Route exact path="/guide" component={GuidePage} />
          <Route exact path="/retailers">
            <Header />
            <Retailers />
          </Route>
          <Route exact path="/shoppers">
            <Header />
            <Shoppers />
          </Route>
          <Route exact path="/investors">
            <Header />
            <Investors />
          </Route>
          <Route exact path="/">
            <Header />
            {/* {!isMobile && <CustomerIn />} */}
            <Landing />
          </Route>
        </Switch>
      </Router>
    </StylesProvider>
  </ThemeProvider>
);

export default App;
