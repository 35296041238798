import { Footer, Header } from 'components';
import React, { FunctionComponent } from 'react';
import styles from './index.module.scss';
import values from '../../assets/values/valores.svg';
import valuesMobile from '../../assets/values/valores_mobile.svg';
import { isMobile } from 'react-device-detect';
const ValuesPage: FunctionComponent = () => {
  return (
    <div>
      <Header />
      <div className={styles.mainSection}>
        <div className={styles.content}>
          <span className={styles.title}>Our Values</span>
          <img src={isMobile ? valuesMobile : values} alt="valores" className={styles.image} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ValuesPage;
