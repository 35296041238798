import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import common from './translations/en/common.json';
import main from './translations/en/main.json';
import commonES from './translations/es/common.json';
import mainES from './translations/es/main.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    resources: {
      en: {
        main,
        common,
      },
      es: {
        main: mainES,
        common: commonES,
      },
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
