import { Footer } from 'components';
import React from 'react';
import InterestedSection from '../Landing/Sections/InterestedSection2';
import styles from './index.module.scss';

const Investors = () => {
  return (
    <div className={styles.investorsContainer}>
      <Footer />
    </div>
  );
};

export default Investors;
