import React, { FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import WithBackground from 'hocs';
import { Icon, FormInput } from 'components';
import styles from './index.module.scss';

type FormData = {
  email: string;
  password: string;
};

const Login: FunctionComponent = () => {
  const { errors, handleSubmit, register } = useForm<FormData>();
  const [itStarted, setItStarted] = useState(false);
  const { t: mainTranslation } = useTranslation('main');
  const { t: commonTranslation } = useTranslation('common');
  const onSubmit = ({ email, password }: FormData) => console.log(email, password);

  return !itStarted ? (
    <>
      <span className={styles.startTitle}>{mainTranslation('loginPage.startTitle')}</span>
      <Button
        className={styles.start}
        endIcon={<Icon color="#000000" icon="next" size={20} />}
        onClick={() => setItStarted(true)}
        variant="outlined"
      >
        {commonTranslation('start')}
      </Button>
    </>
  ) : (
    <>
      <form className={styles.form} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formWrapper}>
          <FormInput
            error={!!errors.email}
            helperText={!!errors.email && commonTranslation('wrongEmail')}
            inputRef={register({
              pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              required: true,
            })}
            label={mainTranslation('loginPage.form.email')}
            name="email"
          />
          <FormInput
            error={!!errors.password}
            helperText={!!errors.password && commonTranslation('fieldRequired')}
            inputRef={register({ required: true })}
            label={mainTranslation('loginPage.form.password')}
            name="password"
            type="password"
          />
          <Link className={styles.forgetPassword} to="/recovery">
            {mainTranslation('loginPage.form.forgetPassword')}
          </Link>
          <Button
            type="submit"
            className={styles.submitButton}
            endIcon={<Icon color="#000000" icon="next" size={20} />}
          >
            <span className={styles.submit}>{mainTranslation('loginPage.form.signIn')}</span>
          </Button>
        </div>
      </form>
      <div className={styles.signUpWrapper}>
        <span className={styles.needAccount}>{mainTranslation('loginPage.form.needAccount')}</span>
        &nbsp;
        <Link className={styles.signUp} to="/register">
          {mainTranslation('loginPage.form.signUp')}
        </Link>
      </div>
    </>
  );
};

export default WithBackground(Login);
