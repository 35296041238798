import React, { FunctionComponent } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import CiruclarPorcentage75 from '../../../../assets/retailers/75_b.svg';
import CiruclarPorcentage62 from '../../../../assets/retailers/62_b.svg';
import Chart from '../../../../assets/retailers/metricas.svg';
//import ChartES from '../../../../assets/retailers/metricas_SPA.svg';
import { ReactComponent as ChartES } from '../../../../assets/retailers/metricas_SPA.svg';
import { AccessibilityNew, OpenWith, ContactSupport, ShoppingCart } from '@material-ui/icons';
import { Grid } from '@material-ui/core';
import ParticlesAnimation from 'components/ParticlesAnimation/ParticlesAnimation';
import Icon from 'components/Icon';

const OurMetricsSection: FunctionComponent = () => {
  const { t: mainTranslation, i18n } = useTranslation('main');

  return (
    <div className={styles.ourMetricsSection} id="metrics">
      <ParticlesAnimation
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 0,
          width: '100%',
          height: '100%',
          //objectFit: 'contain',
        }}
        particlesNumberValue={300}
        onHoverMode="repulse"
      />
      <br />
      <span className={styles.title}>{mainTranslation('retailersPage.benefitsTitle')}</span>
      <br />
      <br />
      <Grid container direction="row" justify="center">
        <Grid item lg={2} xs={12}>
          <Grid container direction="column" alignItems="center" className={styles.stepContainer}>
            <div className={styles.circularMetricText}>
              <AccessibilityNew style={{ fontSize: 80, color: 'white' }} />
            </div>
            <br />
            <br />
            <div className={styles.circularMetricText}>{mainTranslation('retailersPage.benefit1')}</div>
          </Grid>
        </Grid>
        <Grid item lg={2} xs={12}>
          <Grid container direction="column" alignItems="center" className={styles.stepContainer}>
            <div className={styles.circularMetricText}>
              <OpenWith style={{ fontSize: 80, color: 'white' }} />
            </div>
            <br />
            <br />
            <div className={styles.circularMetricText}>{mainTranslation('retailersPage.benefit2')}</div>
          </Grid>
        </Grid>
        <Grid item lg={2} xs={12}>
          <Grid container direction="column" alignItems="center" className={styles.stepContainer}>
            <div className={styles.circularMetricText}>
              <ContactSupport style={{ fontSize: 80, color: 'white' }} />
            </div>
            <br />
            <br />
            <div className={styles.circularMetricText}>{mainTranslation('retailersPage.benefit3')}</div>
          </Grid>
        </Grid>
        <Grid item lg={2} xs={12}>
          <Grid container direction="column" alignItems="center" className={styles.stepContainer}>
            <div className={styles.circularMetricText}>
              <ShoppingCart style={{ fontSize: 80, color: 'white' }} />
            </div>
            <br />
            <br />
            <div className={styles.circularMetricText}>{mainTranslation('retailersPage.benefit4')}</div>
          </Grid>
        </Grid>
      </Grid>
      <span className={styles.title}>{mainTranslation('retailersPage.metrics.title')}</span>
      <div className={styles.circularMetricsItem}>
        {/es/.test(i18n.language) ? (
          <ChartES className={styles.metrics} style={{ marginTop: 40 }} />
        ) : (
          <img src={Chart} alt="metrics metricas" className={styles.metrics} />
        )}
      </div>
      <Grid container style={{ marginTop: '2rem', width: '70%', alignSelf: 'center' }} justify="center">
        <Grid xs={12} lg={5} style={{ alignSelf: 'center' }}>
          <a
            href="https://yougov.co.uk/topics/politics/articles-reports/2011/05/16/retail-multi-channel-gaps"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={CiruclarPorcentage75} alt="circularMetric75" className={styles.circularPorcentage} />
          </a>
        </Grid>
        <Grid xs={12} lg={6} style={{ alignSelf: 'center' }}>
          <span className={styles.circularMetricText}>{mainTranslation('retailersPage.metricsCircular75')}</span>
        </Grid>
      </Grid>
      <Grid
        container
        style={{ marginTop: '2rem', marginBottom: '2rem', width: '70%', alignSelf: 'center' }}
        justify="center"
      >
        <Grid xs={12} lg={5} style={{ alignSelf: 'center' }}>
          <a
            href="https://www.retaildive.com/news/why-most-shoppers-still-choose-brick-and-mortar-stores-over-e-commerce/436068/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={CiruclarPorcentage62} alt="circularMetric62" className={styles.circularPorcentage} />
          </a>
        </Grid>
        <Grid xs={12} lg={6} style={{ alignSelf: 'center' }}>
          <span className={styles.circularMetricText}>{mainTranslation('retailersPage.metricsCircular62')}</span>
        </Grid>
      </Grid>
    </div>
  );
};

export default OurMetricsSection;
