import React, { FunctionComponent, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { experiences } from '../../../../constants';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import ParticlesAnimationTwo from 'components/ParticlesAnimation/ParticlesAnimationTwo';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Collapse from '@material-ui/core/Collapse';
import { Button } from '@material-ui/core';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
//import Viewer from './Viewer/Viewer';
import { Carousel } from 'react-responsive-carousel';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

const auxSrc = [
  'https://3dmodelsinyourweb.go2future.com/landing-Landing_1',
  'https://3dmodelsinyourweb.go2future.com/landing-Landing_2',
  'https://3dmodelsinyourweb.go2future.com/landing-Landing_3',
  'https://3dmodelsinyourweb.go2future.com/landing-Landing_4',
];

const ExperienceSection: FunctionComponent = () => {
  const { t: commonTranslation } = useTranslation('common');
  const [isStyle, setIsStyle] = useState(false);
  const [isViewer, setIsViewer] = useState(true);
  const [isFullscreenIcon, setIsFullscreenIcon] = useState(false);
  const handle = useFullScreenHandle();

  return (
    <div className={styles.experienceSection}>
      <ParticlesAnimationTwo />
      <div className={styles.experienceWrapper}>
        {experiences.map(({ image, text, title }, index) => (
          <div className={styles.experience} key={title}>
            <img src={image} alt={commonTranslation(title)} className={styles.image} />
            <span className={styles.experienceTitle} dangerouslySetInnerHTML={{ __html: commonTranslation(title) }} />
            <span className={styles.experienceText} dangerouslySetInnerHTML={{ __html: commonTranslation(text) }} />
            {index === 0 && (
              <VisibilityIcon
                className={isStyle ? styles.experienceIconNoEffect : styles.experienceIcon}
                onMouseEnter={() => {
                  setIsStyle(true);
                }}
                onClick={() => {
                  setIsViewer((prev) => !prev);
                  //setCurrentHeight(ref?.current?.clientHeight!);
                }}
              />
            )}
          </div>
        ))}
      </div>
      {isViewer && (
        <Collapse in={isViewer} style={{ alignSelf: 'center', marginTop: '4%' }}>
          <FullScreen
            handle={handle}
            // onChange={() => {
            //   console.log('entra???');
            //   setIsFullscreenIcon((prev) => !prev);
            // }}
          >
            <div
              style={{
                width: isFullscreenIcon ? '100vw' : '80vw',
                height: isFullscreenIcon ? '100vh' : '80vh',
                position: 'relative',
              }}
            >
              <Button
                onClick={() => {
                  isFullscreenIcon ? handle.exit() : handle.enter();
                  setIsFullscreenIcon((prev) => !prev);
                }}
                style={{ position: 'absolute', top: 10, right: 10, zIndex: 2 }}
              >
                {isFullscreenIcon ? (
                  <FullscreenIcon alt="fullscreen" style={{ width: '2em', height: '2em', color: 'white' }} />
                ) : (
                  <FullscreenExitIcon alt="fullscreen" style={{ width: '2em', height: '2em', color: 'white' }} />
                )}
              </Button>
              <div
                style={{
                  position: 'absolute',
                  width: isFullscreenIcon ? '100vw' : '79vw',
                  //height: isFullscreenIcon ? '100%' : '120vh',
                  height: '100%',
                  top: 0,
                  left: isFullscreenIcon ? 0 : '1%',
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center',
                  placeContent: 'center',
                }}
              >
                <Carousel
                  showThumbs={false}
                  autoPlay
                  infiniteLoop
                  interval={5000}
                  showStatus={false}
                  renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                      <button
                        //type="button"
                        onClick={onClickHandler}
                        title={label}
                        style={{
                          position: 'absolute',
                          zIndex: 2,
                          top: 'calc(50% - 15px)',
                          width: 30,
                          height: 30,
                          cursor: 'pointer',
                          left: 15,
                          backgroundColor: 'transparent',
                          border: 'none',
                        }}
                      >
                        <ArrowBackIosIcon style={{ color: 'white', fontSize: '3em' }} />
                      </button>
                    )
                  }
                  renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                      <button
                        type="button"
                        onClick={onClickHandler}
                        title={label}
                        style={{
                          position: 'absolute',
                          zIndex: 2,
                          top: 'calc(50% - 15px)',
                          width: 30,
                          height: 30,
                          cursor: 'pointer',

                          backgroundColor: 'transparent',
                          border: 'none',
                          right: 15,
                        }}
                      >
                        <ArrowForwardIosIcon style={{ color: 'white', fontSize: '3em' }} />
                      </button>
                    )
                  }
                  renderIndicator={(onClickHandler, isSelected, index, label) => {
                    if (isSelected) {
                      return (
                        <li
                          style={{
                            width: 10,
                            height: 10,
                            display: 'inline-block',
                            margin: '0 8px',
                            background: 'rgb(165 159 159)',
                            borderRadius: '50%',
                            //cursor:"pointer"
                          }}
                          aria-label={`Selected: ${label} ${index + 1}`}
                          title={`Selected: ${label} ${index + 1}`}
                        />
                      );
                    }
                    return (
                      <li
                        style={{
                          background: '#fff',
                          width: 11,
                          height: 11,
                          display: 'inline-block',
                          borderRadius: '50%',
                          margin: '0 8px',
                          cursor: 'pointer',
                        }}
                        onClick={onClickHandler}
                        onKeyDown={onClickHandler}
                        value={index}
                        key={index}
                        role="button"
                        tabIndex={0}
                        title={`${label} ${index + 1}`}
                        aria-label={`${label} ${index + 1}`}
                      />
                    );
                  }}
                >
                  {auxSrc.map(function (src) {
                    return (
                      <div style={{ marginBottom: isFullscreenIcon ? '0%' : '5%' }} key="src">
                        <iframe
                          title="3dModel"
                          loading="lazy"
                          src={src}
                          style={{
                            width: isFullscreenIcon ? '92%' : '50%',
                            height: isFullscreenIcon ? '83vh' : '60vh',
                            border: 'none',
                          }}
                        >
                          <p style={{ color: 'white' }}>Your browser does not support iframes.</p>
                        </iframe>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
              /> */}
            </div>
          </FullScreen>
        </Collapse>
      )}{' '}
    </div>
  );
};
export default ExperienceSection;
