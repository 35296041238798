import React, { FunctionComponent } from 'react';
import IcomoonReact from 'icomoon-react';

import iconSet from 'assets/icomoon/selection.json';

interface IconProps {
  color?: string;
  size?: string | number;
  icon: string;
  className?: string;
}

const Icon: FunctionComponent<IconProps> = ({ className, color, icon, size = 20 }) => (
  <IcomoonReact className={className} iconSet={iconSet} color={color} size={size} icon={icon} />
);

export default Icon;
