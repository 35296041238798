import React, { FunctionComponent, RefObject } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon';
import styles from './index.module.scss';

interface MenuItem {
  icon: string;
  language: string;
  name: string;
  lng: string;
}

interface TransformOrigin {
  horizontal: 'center' | 'left' | 'right' | number;
  vertical: 'bottom' | 'center' | 'top' | number;
}

interface LanguageMenuProps {
  anchorRef: RefObject<HTMLButtonElement>;
  disableScrollLock?: boolean;
  isVisible: boolean;
  menuItems: Array<MenuItem>;
  onClose: () => void;
  //onItemSelect: (language: string) => void;
  transformOrigin?: TransformOrigin;
}

const LanguageMenu: FunctionComponent<LanguageMenuProps> = ({
  anchorRef,
  disableScrollLock = false,
  isVisible,
  menuItems,
  onClose,
  //onItemSelect,
  transformOrigin = { horizontal: 'left', vertical: 'top' },
}) => {
  const { t, i18n } = useTranslation('common');

  return (
    <Menu
      anchorEl={anchorRef.current}
      classes={{ paper: styles.menu }}
      disableScrollLock={disableScrollLock}
      onClose={onClose}
      open={isVisible}
      transformOrigin={transformOrigin}
    >
      {menuItems.map(({ icon, language, name, lng }) => (
        <MenuItem
          key={name}
          classes={{ root: styles.menuItem }}
          onClick={() => {
            onClose();
            //onItemSelect(language);
            i18n.changeLanguage(lng);
          }}
        >
          <Icon icon={icon} />
          <span className={styles.language}>{t(name)}</span>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default LanguageMenu;
