import React, { FunctionComponent } from 'react';
import { Typography, Divider, Button } from '@material-ui/core';
import { AvailablePlatforms } from 'components';
// @ts-ignore
import Main from './Main.mp4';
import { isMobile } from 'react-device-detect';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { headerLinks } from '../../../../constants';
import logo from '../../../../assets/02 MetaverseMallwhite Logo.png';

const MainSection: FunctionComponent = () => {
  const { t: mainTranslation } = useTranslation('main');
  const { t: commonTranslation } = useTranslation('common');

  return (
    <div className={styles.mainSection}>
      <div style={{ background: 'rgba(0, 0, 0, 0.7)', textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
        <span
          style={{
            paddingTop: 25,
            whiteSpace: 'pre-line',
          }}
        >
          {/* {mainTranslation('topSection.title')} */}

          <img
            src={logo}
            alt="logo"
            style={{
              paddingTop: 100,
              height: 200,
            }}
          />
        </span>
        <div
          style={{
            width: '100vw',
            display: 'flex',
            flexDirection: 'row',
            textAlign: 'left',
            placeContent: 'center',
            marginTop: '5%',
            flexWrap: 'wrap',
          }}
        >
          {/* <Typography variant="h4" style={{ margin: '1%', color: 'white' }}>
            {mainTranslation('topSection.subtitle')}
          </Typography>
          <Divider style={{ backgroundColor: 'white', height: 3 }} /> */}
          <Button
            style={{
              backgroundColor: '#51fff5',
              borderRadius: 17,
              alignSelf: 'center',
              //marginTop: '6%',
              marginBottom: '2%',
              fontSize: '1.2em',
              marginRight: '2%',
              padding: '1em',
              textAlign: 'center',
            }}
            href={commonTranslation(headerLinks[3].anchor)}
          >
            <span style={{ fontWeight: 600, letterSpacing: 4 }}>{mainTranslation('topSection.startBtn')}</span>
          </Button>
        </div>
      </div>
      <div
        className={styles.content}
        style={{
          display: 'flex',
          flexDirection: 'row',
          //alignItems: 'center',
          justifyContent: 'space-around',
          flexWrap: 'wrap',
        }}
      >
        <video
          autoPlay
          loop
          muted
          style={{
            position: 'fixed',
            width: '100vw',
            height: '130vh',
            top: '50%',
            objectFit: 'cover',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            zIndex: -1,
          }}
        >
          <source src={Main} type="video/mp4" />
        </video>
        <div style={{ display: 'flex', flexDirection: 'column', maxWidth: isMobile ? '100%' : '90vw' }}>
          <span className={styles.title}>{mainTranslation('mainSection.title')}</span>
          <span
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: commonTranslation(mainTranslation('mainSection.subtitle')) }}
          />
          <span className={styles.titleTwo}>{mainTranslation('mainSection.titleTwo')}</span>
          <span
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: commonTranslation(mainTranslation('mainSection.subtitleTwo')) }}
          />

          <AvailablePlatforms />
        </div>
      </div>
    </div>
  );
};

export default MainSection;
