import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import logo from 'assets/logo.svg';
import styles from './index.module.scss';

const withBackground = (WrappedComponent: FunctionComponent): FunctionComponent => {
  const WithBackground = () => (
    <div className={styles.background}>
      <div className={styles.content}>
        <Link to="/">
          <img alt="logo" className={styles.logo} src={logo} />
        </Link>
        <WrappedComponent />
      </div>
    </div>
  );

  WithBackground.displayName = `withBackground(${WrappedComponent.name})`;

  return WithBackground;
};

export default withBackground;
