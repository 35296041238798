import { Button, Drawer, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { FunctionComponent } from 'react';
import DownloadButton from '../DownloadButton';
import { headerLinks } from '../../constants';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

interface MenuProps {
  isMobile: boolean;
  isVisible: boolean;
  onClose: () => void;
}

const Menu: FunctionComponent<MenuProps> = ({ isMobile, isVisible, onClose }) => {
  const { t } = useTranslation('common');
  const platforms = ['windows', 'mac'];

  return (
    <Drawer
      classes={{
        paper: styles.drawer,
      }}
      onClose={onClose}
      open={isVisible}
    >
      <IconButton className={styles.close} onClick={onClose}>
        <Close fontSize="large" />
      </IconButton>
      <Button onClick={() => window.location.replace(headerLinks[0].anchor)} className={styles.menuLink}>
        {t(headerLinks[0].text)}
      </Button>
      <Button onClick={() => window.location.replace(headerLinks[1].anchor)} className={styles.menuLink}>
        {t(headerLinks[1].text)}
      </Button>
      <a className={styles.noLink} href="mailto:hola@go2future.com" key={headerLinks[2].text}>
        <Button className={styles.menuLink}>{t(headerLinks[2].text)}</Button>
      </a>

      {
        //   <Button
        //   variant="outlined"
        //   className={styles.menuGoIn}
        //   endIcon={<Icon icon="next" size={20} />}
        //   onClick={() => history.push('/login')}
        // >
        //   {t('goIn')}
        // </Button>
      }
      <DownloadButton availablePlatforms={platforms} title={t('download')} />
      {
        //   <div className={styles.help}>
        //   <HelpOutline htmlColor="#51fff5" />
        //   <span className={styles.helpText}>{t('help')}</span>
        // </div>
      }
    </Drawer>
  );
};

export default Menu;
