import { MainSection } from './Sections';
import React, { FunctionComponent, Suspense } from 'react';
import { Footer } from 'components';
const VideoSection = React.lazy(() => import('./Sections/VideoSection'));
const ExperienceSection = React.lazy(() => import('./Sections/ExperienceSection'));
const TechnologiesSection = React.lazy(() => import('./Sections/TechnologiesSection'));
const PartnersSection = React.lazy(() => import('./Sections/PartnersSection'));
const Landing: FunctionComponent = () => (
  <div>
    <MainSection />
    <Suspense fallback={<div style={{ background: 'black' }}></div>}>
      <ExperienceSection />
      <TechnologiesSection />
      <PartnersSection />
    </Suspense>
    <Footer />
  </div>
);

export default Landing;
