import { AppBar, Button, Hidden, IconButton, Theme, useMediaQuery } from '@material-ui/core';
import React, { FunctionComponent, useRef, useState } from 'react';
import { Menu as MenuIcon } from '@material-ui/icons';
import logo from 'assets/01 MetaverseMallbyGO2white Logo sin letras.png';
import { useTranslation } from 'react-i18next';
import {
  headerLinks,
  /*languageMenu,*/
  // commonShoppersMenu,
  // commonRetailersMenu,
  footerLanguageMenu,
  commonRetailersMenuAux,
  commonShoppersMenuAux,
} from '../../constants';
//import DownloadButton from '../DownloadButton';
import HeaderMenu from '../Menu';
import Icon from '../Icon';
//import LanguageMenu from '../LanguageMenu';
import styles from './index.module.scss';
import CollapsableMenu from '../CollapsableMenu';
import LanguageMenu from 'components/LanguageMenu';
import ParticlesAnimation from 'components/ParticlesAnimation/ParticlesAnimation';
//import { useHistory } from 'react-router';

const Header: FunctionComponent = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  //const history = useHistory();

  //const anchorRef = useRef<HTMLButtonElement>(null);
  const anchorRetailersRef = useRef<HTMLButtonElement>(null);
  const anchorShoppersRef = useRef<HTMLButtonElement>(null);

  const { t, i18n } = useTranslation('common');
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  //const [selectedLanguage, setSelectedLanguage] = useState(t('languageMenu.language.english'));

  //const [isLanguageMenuVisible, setIsLanguageMenuVisible] = useState(false);
  const [isRetailersMenuVisible, setIsRetailersMenuVisible] = useState(false);
  const [isShoppersMenuVisible, setIsShoppersMenuVisible] = useState(false);

  //const toggleLanguageMenu = () => setIsLanguageMenuVisible(!isLanguageMenuVisible);
  const toggleRetailersMenu = () => setIsRetailersMenuVisible(!isRetailersMenuVisible);
  const toggleShoppersMenu = () => setIsShoppersMenuVisible(!isShoppersMenuVisible);

  const toggleLanguageMenu = () => setIsLanguageMenuVisible(!isLanguageMenuVisible);
  const [isLanguageMenuVisible, setIsLanguageMenuVisible] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  //const platforms = ['windows', 'mac'];

  //const setSelectedLanguage = (language: string) => { };
  return (
    <AppBar classes={{ root: styles.header }} position="fixed">
      <ParticlesAnimation
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 0,
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        }}
        particlesNumberValue={400}
        onHoverMode="repulse"
      />
      <span className={styles.headerCol}>
        <Hidden mdUp>
          <IconButton onClick={() => setIsMenuVisible(true)}>
            <MenuIcon htmlColor="#ffffff" alt="menu" />
          </IconButton>
        </Hidden>
        <Button onClick={() => window.location.replace('/')}>
          <img src={logo} alt="logo" className={styles.logo} />
        </Button>
      </span>
      <div className={styles.headerCol}>
        <Hidden smDown>
          <Button
            className={styles.link}
            onClick={toggleRetailersMenu}
            key={headerLinks[0].text}
            ref={anchorRetailersRef}
          >
            {t(headerLinks[0].text)}
            <Icon className={styles.arrowDown} color="#FFFFFF" icon="down" />
          </Button>
          <Button
            className={styles.link}
            onClick={toggleShoppersMenu}
            key={headerLinks[1].text}
            ref={anchorShoppersRef}
          >
            {t(headerLinks[1].text)}
            <Icon className={styles.arrowDown} color="#FFFFFF" icon="down" />
          </Button>
          {/* <Link className={styles.noLink} to="/retailers" key={headerLinks[0].text}>
               <Button className={styles.link}>{t(headerLinks[0].text)}</Button>
           </Link> */}
          {/* <Link className={styles.noLink} to="/shoppers" key={headerLinks[1].text}>
            <Button className={styles.link}>{t(headerLinks[1].text)}</Button>
          </Link> */}
          <a className={styles.noLink} href="mailto:hola@go2future.com" key={headerLinks[2].text}>
            <Button className={styles.link}>{t(headerLinks[2].text)}</Button>
          </a>
        </Hidden>
        {/* <Button className={styles.language} onClick={toggleLanguageMenu} ref={anchorRef}>
          {/* {selectedLanguage} 
        <Icon color="#ffffff" icon="language" size={40} />
        <Icon color="#ffffff" icon="down" size={20} />
        </Button> */}

        <Hidden smDown>
          {
            //   <Button
            //   variant="outlined"
            //   className={styles.goIn}
            //   endIcon={<Icon icon="next" size={20} />}
            //   onClick={() => history.push('/login')}
            // >
            //   {t('goIn')}
            // </Button>
          }
          {/* <div className={styles.downloadButton}>
            <DownloadButton availablePlatforms={platforms} title={t('download')} />
        </div> */}
          {/* {history.location.search === '?stag' && ( */}
          <Button className={styles.link} href={t(headerLinks[3].anchor)}>
            {t(headerLinks[3].text)}
          </Button>
        </Hidden>

        <HeaderMenu isMobile={isMobile} isVisible={isMenuVisible} onClose={() => setIsMenuVisible(false)} />

        <CollapsableMenu
          anchorRef={anchorRetailersRef}
          isVisible={isRetailersMenuVisible}
          //menuItems={window.location.search === '?stag' ? commonRetailersMenuAux : commonRetailersMenu}
          menuItems={commonRetailersMenuAux}
          onClose={toggleRetailersMenu}
          transformOrigin={{ horizontal: 0, vertical: -50 }}
          onRedirect={(x) => window.location.replace(x)}
        />

        <CollapsableMenu
          anchorRef={anchorShoppersRef}
          isVisible={isShoppersMenuVisible}
          //menuItems={window.location.search === '?stag' ? commonShoppersMenuAux : commonShoppersMenu}
          menuItems={commonShoppersMenuAux}
          onClose={toggleShoppersMenu}
          onRedirect={(x) => window.location.replace(x)}
          transformOrigin={{ horizontal: 0, vertical: -50 }}
        />
      </div>
      <Hidden smDown>
        <Button className={styles.textAqua} onClick={toggleLanguageMenu} ref={anchorRef}>
          {/* {selectedLanguage} */}
          {t(`footerLanguageMenu.language.${/es/.test(i18n.language) ? 'es' : 'en'}`)}
          <Icon className={styles.arrowDown} color="#51fff5" icon="down" />
        </Button>
        <LanguageMenu
          anchorRef={anchorRef}
          isVisible={isLanguageMenuVisible}
          menuItems={footerLanguageMenu}
          onClose={toggleLanguageMenu}
          //onItemSelect={(language) => setSelectedLanguage(commonTranslation(language))}
          transformOrigin={{ horizontal: -100, vertical: 0 }}
        />
      </Hidden>
    </AppBar>
  );
};

export default Header;
