import { Button, IconButton } from '@material-ui/core';
import React, { FunctionComponent, useState, useEffect } from 'react';
import Icon from '../Icon';
import { Link } from 'react-router-dom';
import getPlatform from 'utils/platform';
import styles from './index.module.scss';

interface DownloadButtonProps {
  availablePlatforms: Array<string>;
  title: string;
}

const DownloadButton: FunctionComponent<DownloadButtonProps> = ({ availablePlatforms, title }) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [buttonTitle, setButtonTitle] = useState(title);
  const [platform] = useState(getPlatform());
  const toggleMenu = () => setIsMenuVisible(!isMenuVisible);
  const [selectedPlatform, setSelectedPlatform] = useState(getPlatform());

  const setPlatformTitle = () => {
    if (!available()) {
      setButtonTitle(`Available soon for ${platform}`);
    }
  };

  const available = () => {
    return platform === 'windows' || platform === 'mac';
  };

  useEffect(setPlatformTitle, []);

  return (
    <>
      <div className={styles.downloadButton}>
        {isMenuVisible ? (
          availablePlatforms.map((availablePlatform) => (
            <IconButton
              key={availablePlatform}
              onClick={() => {
                setTimeout(() => setSelectedPlatform(availablePlatform), 200);
                toggleMenu();
              }}
            >
              <Icon color="#ffffff" icon={availablePlatform} size={20} />
            </IconButton>
          ))
        ) : (
          <>
            <Icon className={styles.macIcon} color="#ffffff" icon={selectedPlatform} size={20} />
            <Button className={styles.downloadButtonText}>
              <Link
                to={{
                  pathname: available() ? '/download' : '/',
                  state: { selectedPlatform },
                }}
              >
                <span className={styles.download}>{buttonTitle}</span>
              </Link>
            </Button>
          </>
        )}
        <IconButton onClick={toggleMenu}>
          <Icon icon="down" color="#ffffff" size={20} className={isMenuVisible ? styles.rotate : styles.down} />
        </IconButton>
      </div>
    </>
  );
};

export default DownloadButton;
