import React from 'react';
import Particles from 'react-particles-js';

type OnMode = 'attract' | 'bounce' | 'bubble' | 'connect' | 'grab' | 'light' | 'repulse' | 'slow' | 'trail';

export default function ParticlesAnimation({
  style,
  particlesNumberValue = 260,
  onHoverMode = 'bubble',
  onClickMode = 'repulse',
}: {
  style?: React.CSSProperties | undefined;
  particlesNumberValue?: number;
  onHoverMode?: OnMode;
  onClickMode?: OnMode;
}) {
  return true ? (
    <></>
  ) : (
    <Particles
      style={style}
      params={{
        particles: {
          number: {
            value: particlesNumberValue,
            density: {
              enable: true,
              area: 200,
            },
          },
          color: {
            value: '#51fff5',
          },
          size: {
            //value: 3,
            value: 1,
            random: true,
            anim: {
              //speed: 4,
              //size_min: 0.3,
              speed: 1,
              size_min: 0.2,
            },
          },
          line_linked: {
            enable: false,
            shadow: {
              enable: true,
              color: '#51fff5',
              blur: 5,
            },
          },
          //   stroke: {
          //     color: '#51fff5',
          //     width: 500,
          //   },
          move: {
            random: true,
            speed: 1,
            direction: 'top',
            out_mode: 'out',
          },
        },
        interactivity: {
          events: {
            onhover: {
              enable: true,
              //mode:"bubble",
              mode: onHoverMode,
              //   parallax: {
              //     enable: true,
              //     force: 5,
              //     smooth: 2,
              //   },
            },
            onclick: {
              enable: true,
              mode: onClickMode,
            },
          },
          modes: {
            bubble: {
              distance: 250,
              duration: 2,
              size: 0,
              opacity: 0,
            },
            repulse: {
              //distance: 400,
              distance: 200,
              duration: 4,
            },
          },
        },
      }}
    />
  );
}
