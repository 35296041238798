import { Button, MenuItem } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { FormInput } from 'components';
import React, { FunctionComponent } from 'react';

import classNames from 'classnames';
import { interestOptions } from '../../../../constants';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import ParticlesAnimation from 'components/ParticlesAnimation/ParticlesAnimation';

type FormData = {
  fullName: string;
  interest: string;
  email: string;
  phone: string;
  message: string;
};

const FormSection: FunctionComponent = () => {
  const { t: mainTranslation } = useTranslation('main');
  const { t: commonTranslation } = useTranslation('common');
  const { control, errors, handleSubmit, register } = useForm<FormData>({
    mode: 'onBlur',
  });
  const onSubmit = ({ fullName, interest, email, phone, message }: FormData) =>
    console.log(fullName, interest, email, phone, message);
  console.log(errors);

  return (
    <div className={styles.formSection} id="contact">
      <ParticlesAnimation
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 0,
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        }}
        particlesNumberValue={400}
        onHoverMode="repulse"
      />
      <span className={styles.titleContainer}>
        <span className={styles.title}>{mainTranslation('formSection.titlePartOne')}</span>
        <span className={styles.titleLightAqua}>{mainTranslation('formSection.titlePartTwo')}</span>
        <span className={styles.title}>{mainTranslation('formSection.titlePartThree')}</span>
      </span>
      <span className={styles.subtitle}>{mainTranslation('formSection.subtitle')}</span>
      <span className={styles.boldSubtitle}>{mainTranslation('formSection.boldSubtitle')}</span>
      <form className={styles.form} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.inputWrapper}>
          <FormInput
            className={styles.field}
            error={!!errors.fullName}
            helperText={!!errors.fullName && commonTranslation('fieldRequired')}
            inputRef={register({ required: true })}
            label={mainTranslation('formSection.form.fullName')}
            name="fullName"
          />
          <Controller
            control={control}
            name="interest"
            defaultValue=""
            rules={{ required: true }}
            as={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <FormInput
                className={styles.field}
                error={!!errors.interest}
                helperText={!!errors.interest && commonTranslation('fieldRequired')}
                label={mainTranslation('formSection.form.interest')}
                select
              >
                {interestOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {commonTranslation(option.label)}
                  </MenuItem>
                ))}
              </FormInput>
            }
          />
        </div>
        <div className={styles.inputWrapper}>
          <FormInput
            className={styles.field}
            error={!!errors.email}
            helperText={!!errors.email && commonTranslation('wrongEmail')}
            inputRef={register({
              pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              required: true,
            })}
            label={mainTranslation('formSection.form.email')}
            name="email"
          />
          <FormInput
            className={styles.field}
            inputRef={register}
            label={mainTranslation('formSection.form.phone')}
            name="phone"
          />
        </div>
        <div className={styles.inputWrapper}>
          <FormInput
            fullWidth
            inputRef={register}
            label={mainTranslation('formSection.form.message')}
            multiline
            name="message"
            rows={3}
            rowsMax={3}
            textArea
          />
        </div>
        <Button
          disabled={!!errors.email || !!errors.fullName}
          type="submit"
          className={classNames(styles.submitButton, (!!errors.email || !!errors.fullName) && styles.submitDisabled)}
        >
          <span className={styles.submit}>{commonTranslation('submit')}</span>
        </Button>
      </form>
    </div>
  );
};

export default FormSection;
